var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
let Bitrix24Callback = class Bitrix24Callback extends Vue {
    constructor() {
        super(...arguments);
        this.code = '';
    }
    mounted() {
        //@ts-ignore
        this.code = decodeURI(this.$route.query.code);
        this.getTokenBitrix24();
    }
    async getTokenBitrix24() {
        //@ts-ignore
        await this.actions.getTokenBitrix24({ code: this.code });
    }
};
Bitrix24Callback = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.users,
        actions: actions.users,
    }), {
        name: 'Bitrix24Callback',
    }))
], Bitrix24Callback);
export default Bitrix24Callback;
